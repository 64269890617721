


























































































































































































#pageContol {
  table {
    width: 100% !important;
  }
  .framePage-body {
    // background: url("../../assets/newIndex.png") no-repeat;
    // background-size: 100% 100%;
  }
  .newIndex {
    width: 100%;
    height: 100%;
    // background: url("../../assets/newIndex.png") no-repeat;
    background-size: 100%;
    position: relative;
    .box-card {
      width: 30%;
      min-width: 450px;
      background: rgba(92, 107, 232, 1);
      box-shadow: 0px 5px 24px 0px rgba(92, 92, 92, 0.35);
      border-radius: 10px;
      position: absolute;
      right: 8%;
      .el-card__body {
        height: 100%;
        .announcement {
          margin: 0 auto;
          margin-top: 20px;
          background: rgba(255, 255, 255, 1);
          width: 100%;
          height: 100%;
          border-radius: 4px;

          .el-table {
            border-radius: 10px;
            height: 100%;
            .el-table__body-wrapper {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
  .flexbox {
    .numberCount {
      display: flex;
      padding: 10px;
      align-items: center;
      > div {
        padding: 15px 25px;
        background: chartreuse;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
      > p {
        font-size: 20px;
      }
    }
  }
}

.tittleIndex {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.theText {
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.incontent {
  width: 400px;
}
